import { Box, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import useAuth from "src/hooks/useAuth";
import useQueryParams from "src/hooks/useQueryParams";
import axiosInstance from "src/utils/axios";
import { setSession } from "src/utils/jwt";

const CheckIP = () => {
  const { queryObject } = useQueryParams();
  const { key } = queryObject || {};
  const { getUser } = useAuth();
  const makeRequest = async () => {
    try {
      const { status, data } = await axiosInstance.get(`api/user/check-ip`);

      if (status === 200) {
        getUser();
        console.log(data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    makeRequest();
  }, []);
  return (
    <Box
      sx={{
        alignItems: "center",
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      TEST PATH
    </Box>
  );
};

export default CheckIP;
