import { Box, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import useAuth from "src/hooks/useAuth";
import useQueryParams from "src/hooks/useQueryParams";
import axiosInstance from "src/utils/axios";
import { setSession } from "src/utils/jwt";

const ImpersonateToken = () => {
  const { queryObject } = useQueryParams();
  const { key } = queryObject || {};
  const { getUser } = useAuth();
  const makeRequest = async () => {
    try {
      const reqData = new FormData();
      const Newkey = decodeURIComponent(key.replace(/\s/g, "+"));
      reqData.append("key", Newkey);
      const { status, data } = await axiosInstance.post(
        `api/get-user-details`,
        reqData
      );

      const { package_status, menu_lists, access_token, user } = data;
      if (status === 200) {
        localStorage.setItem("accessToken", access_token);
        localStorage.setItem("menu", JSON.stringify(menu_lists));
        localStorage.setItem("isAdmin", Boolean(user.is_super_admin));
        localStorage.setItem("isSubAdmin", Boolean(user.is_sub_admin));
        localStorage.setItem("package_status", Boolean(package_status));
        setSession(access_token);
        window.location = "/user/dashboard";
        getUser();
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (key) {
      makeRequest();
    }
  }, [key]);
  return (
    <Box
      sx={{
        alignItems: "center",
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default ImpersonateToken;
